//this is the first lesson and we will export it 
//this will hold 100 questions (explanations included)

export const linearEqOneVar = 
{
    title: "Linear equations in one variable ",
    order: 1,
    questionsAnsweredCorrcetly: 0,
    practice: [

      //HARD LAND 
 

      {
        associatedID: "90095507",
        type: "multiple-choice",
        question: "The Townsend Realty Group purchased the Glenview Street property and received a 40% discount off the original price along with an additional 20% off the discounted price for purchasing the property in cash. Which of the following best approximates the original price, in dollars, of the Glenview Street property?",
        options: [
          "$350,000",
          "$291,700",
          "$233,300",
          "$175,000"
        ],
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/avantdemy2.firebasestorage.app/o/townsend.png?alt=media&token=a2c2b12d-a6a3-4fc4-a16c-2578f5a2165b',
        correctAnswerIndex: 1,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "The correct answer is $291,700. To find the original pråice, we must account for both discounts applied to the property."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Represent the original price of the property as $$x$$.",
                  latex: "$$x = \\text{original price}$$"
                },
                {
                  text: "Step 2: Calculate the price after the 40% discount.",
                  latex: "$$0.6x = \\text{price after 40% discount}$$"
                },
                {
                  text: "Step 3: Calculate the price after the additional 20% discount on the discounted price.",
                  latex: "$$0.8(0.6x) = \\text{final price}$$"
                },
                {
                  text: "Step 4: Set the final price equal to $140,000 and solve for $$x$$.",
                  latex: "$$0.48x = 140,000$$"
                },
                {
                  text: "Step 5: Divide both sides by 0.48 to solve for $$x$$.",
                  latex: "$$x = \\frac{140,000}{0.48} = 291,666.67$$"
                },
                {
                  text: "Conclusion: The original price is approximately $$x = 291,700$$, which matches option B.",
                  latex: ""
                }
              ]
            }
          }
        ]
      }
,      

      {
        associatedID: "2937ef4f",
        type: "multiple-choice",
        question: "Hector used a tool called an auger to remove corn from a storage bin at a constant rate. The bin contained $$24,000$$ bushels of corn when Hector began to use the auger. After $$5$$ hours of using the auger, $$19,350$$ bushels of corn remained in the bin. If the auger continues to remove corn at this rate, what is the total number of hours Hector will have been using the auger when $$12,840$$ bushels of corn remain in the bin?",
        options: ["3", "7", "8", "12"],
        correctAnswerIndex: 3,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "To determine the time it takes to remove enough corn until $$12,840$$ bushels remain, we calculate the rate of removal and set up an equation."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Determine the rate of removal.",
                  latex: "Rate = \\frac{24,000 - 19,350}{5} = 930 \\text{ bushels per hour}"
                },
                {
                  text: "Step 2: Use the rate of removal to find the time required for $$12,840$$ bushels to remain.",
                  latex: "24,000 - 930x = 12,840"
                },
                {
                  text: "Step 3: Solve for $$x$$.",
                  latex: "930x = 24,000 - 12,840 = 11,160 \\implies x = \\frac{11,160}{930} = 12"
                },
                {
                  text: "Conclusion: It will take $$12$$ hours for Hector to reach $$12,840$$ bushels remaining in the bin.",
                  latex: ""
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "38bf4e04",
        type: "multiple-choice",
        question: "A factory makes $$9$$-inch, $$7$$-inch, and $$4$$-inch concrete screws. During a certain day, the number of $$9$$-inch concrete screws that the factory makes is $$5$$ times the number $$n$$ of $$7$$-inch concrete screws, and the number of $$4$$-inch concrete screws is $$22$$. During this day, the factory makes $$100$$ concrete screws total. Which equation represents this situation?",
        options: [
          "$$9(5n) + 7n + 4(22) = 100$$",
          "$$9n + 7n + 4n = 100$$",
          "$$5n + 22 = 100$$",
          "$$6n + 22 = 100$$"
        ],
        correctAnswerIndex: 3,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "We are given relationships between the number of screws of each size and the total number of screws. Let's set up an equation to represent this situation."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Express the number of $$9$$-inch screws in terms of $$n$$.",
                  latex: "Number \\text{ of } 9\\text{-inch screws} = 5n"
                },
                {
                  text: "Step 2: Write the total screws equation.",
                  latex: "5n + n + 22 = 100"
                },
                {
                  text: "Step 3: Simplify the equation.",
                  latex: "6n + 22 = 100"
                },
                {
                  text: "Step 4: Verify.",
                  latex: "This matches the correct option $$D$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "b7e6394d",
        type: "multiple-choice",
        question: "Alan drives an average of $$100$$ miles each week. His car can travel an average of $$25$$ miles per gallon of gasoline. Alan would like to reduce his weekly expenditure on gasoline by $$5$$ dollars. Assuming gasoline costs $$4$$ dollars per gallon, which equation can Alan use to determine how many fewer average miles, $$m$$, he should drive each week?",
        options: [
          "$$\\frac{25}{4}m = 95$$",
          "$$\\frac{25}{4}m = 5$$",
          "$$\\frac{4}{25}m = 95$$",
          "$$\\frac{4}{25}m = 5$$"
        ],
        correctAnswerIndex: 3,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "We aim to find the number of miles $$m$$ Alan needs to reduce to save $$5$$ dollars in gasoline costs."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Calculate the cost per mile.",
                  latex: "Cost \\text{ per mile } = \\frac{4}{25} \\text{ dollars}"
                },
                {
                  text: "Step 2: Relate the cost reduction to the number of miles.",
                  latex: "\\frac{4}{25}m = 5"
                },
                {
                  text: "Step 3: Verify the correct equation.",
                  latex: "This matches option $$D$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "69f609b2",
        type: "multiple-choice",
        question: "How many solutions does the equation $$12(x - 3) = -3(x + 12)$$ have?",
        options: ["Exactly one", "Exactly two", "Infinitely many", "Zero"],
        correctAnswerIndex: 0,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "We solve the given equation step-by-step to determine the number of solutions."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Distribute $$12$$ and $$-3$$.",
                  latex: "$$12x - 36 = -3x - 36$$"
                },
                {
                  text: "Step 2: Combine like terms.",
                  latex: "$$15x - 36 = -36$$"
                },
                {
                  text: "Step 3: Solve for $$x$$.",
                  latex: "$$15x = 0 \\implies x = 0$$"
                },
                {
                  text: "Conclusion: The equation has exactly one solution, $$x = 0$$.",
                  latex: ""
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "771bd0ca",
        type: "multiple-choice",
        question: "If $$5(t + 3) - 7(t + 3) = 38$$, what value of $$t$$ is the solution to the given equation?",
        options: ["$$-22$$", "$$10$$", "$$15$$", "$$22$$"],
        correctAnswerIndex: 0,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "To solve for $$t$$, simplify and isolate the variable step by step."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Combine like terms on the left-hand side.",
                  latex: "$$-2(t + 3) = 38$$"
                },
                {
                  text: "Step 2: Simplify and solve.",
                  latex: "$$t = -22$$"
                },
                {
                  text: "Conclusion: $$t = -22$$ is the solution, which matches option $$A$$.",
                  latex: ""
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "7d5d1b32",
        type: "multiple-choice",
        question: "In the given equation, $$2(kx - n) = \\frac{-28}{15}x - \\frac{36}{19}$$, where $$k$$ and $$n$$ are constants and $$n > 1$$. The equation has no solution. What is the value of $$k$$?",
        options: ["$$-\\frac{14}{15}$$", "$$-0.9333$$", "$$-\\frac{28}{30}$$", "$$\\frac{-36}{19}$$"],
        correctAnswerIndex: 0,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "To find $$k$$ when the equation has no solution, we analyze the coefficients of $$x$$ and constant terms."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Expand and simplify the given equation.",
                  latex: "$$kx - n = \\frac{-28}{15}x - \\frac{36}{19}$$"
                },
                {
                  text: "Step 2: Analyze the conditions for no solution.",
                  latex: "Coefficients of $$x$$ must be equal, and constant terms must differ."
                },
                {
                  text: "Step 3: Solve for $$k$$.",
                  latex: "$$k = -\\frac{14}{15}$$"
                },
                {
                  text: "Conclusion: The value of $$k$$ is $$-\\frac{14}{15}$$.",
                  latex: ""
                }
              ]
            }
          }
        ]
      },

      {
        associatedID: "ae2287e2",
        type: "multiple-choice",
        question: "A certain product costs a company $65 to make. The product is sold by a salesperson who earns a commission that is equal to 20% of the sales price of the product. The profit the company makes for each unit is equal to the sales price minus the combined cost of making the product and the commission. If the sales price of the product is $100, which of the following equations gives the number of units, $$u$$, of the product the company sold to make a profit of $6,840?",
        options: [
          "$$(100(1-0.2)-65)u = 6,840$$",
          "$$(100-65)(1-0.8)u = 6,840$$",
          "$$0.8(100-65)u = 6,840$$",
          "$$0.2(100)+65u = 6,840$$"
        ],
        correctAnswerIndex: 0,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "The equation $$100(1 - 0.2) - 65$$ represents the profit made per unit sold after deducting both commission and the cost of making the product."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Calculate the revenue per unit after commission.",
                  latex: "$$100(1 - 0.2) = 80$$"
                },
                {
                  text: "Step 2: Subtract the cost of production to get the profit per unit.",
                  latex: "$$80 - 65 = 15$$"
                },
                {
                  text: "Step 3: Set up the equation for total profit.",
                  latex: "$$15u = 6,840$$"
                },
                {
                  text: "Step 4: Divide both sides by 15 to solve for $$u$$.",
                  latex: "$$u = \\frac{6,840}{15}$$"
                },
                {
                  text: "Conclusion: The correct equation is $$100(1 - 0.2) - 65u = 6,840$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "e6cb2402",
        type: "multiple-choice",
        question: "In the given equation $$3(kx + 13) = \\frac{48}{17}x + 36$$, the equation has no solution. What is the value of $$k$$?",
        options: [
          "0.9411",
          "16/17",
          "0.9412",
          "17/16"
        ],
        correctAnswerIndex: 1,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "For the equation to have no solution, the coefficients of $$x$$ on both sides must be equal, but the constants must not match."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Distribute the left-hand side.",
                  latex: "$$3kx + 39 = \\frac{48}{17}x + 36$$"
                },
                {
                  text: "Step 2: Equate coefficients of $$x$$.",
                  latex: "$$3k = \\frac{48}{17}$$"
                },
                {
                  text: "Step 3: Solve for $$k$$ by dividing both sides by 3.",
                  latex: "$$k = \\frac{48}{17 \\times 3} = \\frac{16}{17}$$"
                },
                {
                  text: "Conclusion: $$k = \\frac{16}{17}$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "25e1cfed",
        type: "multiple-choice",
        question: "How many solutions does the equation $$10(15x-9) = -15(6-10x)$$ have?",
        options: [
          "Exactly one",
          "Exactly two",
          "Infinitely many",
          "Zero"
        ],
        correctAnswerIndex: 2,
        difficulty_level: 3,
        explanation: [
          {
            type: "text",
            data: "To determine the number of solutions, simplify both sides of the equation and check for equality."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Apply the distributive property to both sides.",
                  latex: "$$150x - 90 = -90 + 150x$$"
                },
                {
                  text: "Step 2: Combine like terms.",
                  latex: "$$150x = 150x$$"
                },
                {
                  text: "Step 3: Observe that both sides of the equation are identical, indicating infinitely many solutions."
                },
                {
                  text: "Conclusion: The equation has infinitely many solutions."
                }
              ]
            }
          }
        ]
      },



      






















      
      //MEDIUM LAND 
      {
        associatedID: "7a5a74a6",
        type: "multiple-choice",
        question: "If $$x$$ is the solution to the equation $$3(2x - 6) - 11 = 4(x - 3) + 6$$, what is the value of $$x - 3$$?",
        options: ["$$\\frac{23}{2}$$", "$$\\frac{17}{2}$$", "$$\\frac{15}{2}$$", "$$2$$"],
        correctAnswerIndex: 1,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To find the value of $$x - 3$$, simplify both sides of the equation and isolate $$x$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Distribute $$3$$ and $$4$$ on the respective sides.",
                  latex: "$$6x - 18 - 11 = 4x - 12 + 6$$"
                },
                {
                  text: "Step 2: Combine like terms on both sides.",
                  latex: "$$6x - 29 = 4x - 6$$"
                },
                {
                  text: "Step 3: Subtract $$4x$$ from both sides to isolate $$x$$ terms.",
                  latex: "$$2x - 29 = -6$$"
                },
                {
                  text: "Step 4: Add $$29$$ to both sides.",
                  latex: "$$2x = 23$$"
                },
                {
                  text: "Step 5: Divide both sides by $$2$$ to solve for $$x$$.",
                  latex: "$$x = \\frac{23}{2}$$"
                },
                {
                  text: "Step 6: Subtract $$3$$ from $$x$$ to find $$x - 3$$.",
                  latex: "$$x - 3 = \\frac{23}{2} - 3 = \\frac{17}{2}$$"
                },
                {
                  text: "Conclusion: The value of $$x - 3$$ is $$\\frac{17}{2}$$, which matches option B."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "aa85b138",
        type: "multiple-choice",
        question: "A tree had a height of $$6$$ feet when it was planted. The equation $$2n + 6 = 14$$ can be used to find how many years $$n$$ it took the tree to reach a height of $$14$$ feet. Which of the following is the best interpretation of the number $$2$$ in this context?",
        options: [
          "The number of years it took the tree to double its height",
          "The average number of feet that the tree grew per year",
          "The height, in feet, of the tree when it was 1 year old",
          "The average number of years it takes similar trees to grow 14 feet"
        ],
        correctAnswerIndex: 1,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "The coefficient $$2$$ represents the average growth in feet per year of the tree after it was planted."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Recognize the structure of the equation.",
                  latex: "$$2n$$ represents the height gained over $$n$$ years."
                },
                {
                  text: "Step 2: Subtract $$6$$ from both sides to isolate $$2n$$.",
                  latex: "$$2n = 14 - 6$$"
                },
                {
                  text: "Step 3: Simplify the equation.",
                  latex: "$$2n = 8$$"
                },
                {
                  text: "Step 4: Solve for $$n$$ by dividing by $$2$$.",
                  latex: "$$n = \\frac{8}{2} = 4$$"
                },
                {
                  text: "Conclusion: $$2$$ represents the average number of feet the tree grew per year, matching option B."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "15daa8d6",
        type: "multiple-choice",
        question: "In the given equation $$2x + 16 = a(x + 8)$$, where $$a$$ is a constant, if the equation has infinitely many solutions, what is the value of $$a$$?",
        options: ["1", "2", "3", "4"],
        correctAnswerIndex: 1,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "For the equation to have infinitely many solutions, both sides must be identical for any value of $$x$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Expand the right-hand side of the equation.",
                  latex: "$$a(x + 8) = ax + 8a$$"
                },
                {
                  text: "Step 2: Rewrite the equation.",
                  latex: "$$2x + 16 = ax + 8a$$"
                },
                {
                  text: "Step 3: For the coefficients of $$x$$ to match, set $$a = 2$$.",
                  latex: ""
                },
                {
                  text: "Step 4: For the constant terms to match, check if $$8a = 16$$.",
                  latex: "$$8(2) = 16$$"
                },
                {
                  text: "Conclusion: The value of $$a$$ is $$2$$, which matches option B."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "12ee1edc",
        type: "multiple-choice",
        question: "In the given equation $$(b - 2)x = 8$$, if the equation has no solution, what is the value of $$b$$?",
        options: ["2", "4", "6", "10"],
        correctAnswerIndex: 0,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "For the equation to have no solution, the denominator in solving for $$x$$ must be $$0$$, making the equation undefined."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Solve for $$x$$ in terms of $$b$$ by dividing both sides by $$(b - 2)$$.",
                  latex: "$$x = \\frac{8}{b - 2}$$"
                },
                {
                  text: "Step 2: For the equation to have no solution, $$(b - 2) = 0$$.",
                  latex: "$$b - 2 = 0$$"
                },
                {
                  text: "Step 3: Solve for $$b$$.",
                  latex: "$$b = 2$$"
                },
                {
                  text: "Conclusion: When $$b = 2$$, the equation has no solution as the denominator becomes undefined, matching option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "f09097b1",
        type: "multiple-choice",
        question: "An agricultural scientist studying the growth of corn plants recorded the height of a corn plant at the beginning of a study and the height of the plant each day for the next 12 days. The scientist found that the height of the plant increased by an average of $$1.20$$ cm per day for $$12$$ days. If the height of the plant on the last day of the study was $$36.8$$ cm, what was the height, in cm, of the corn plant at the beginning of the study?",
        options: ["$$22.4$$", "$$14.4$$", "$$36.8$$", "$$12.4$$"],
        correctAnswerIndex: 0,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To find the initial height of the plant, subtract the total growth over 12 days from the final height."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Calculate the total growth over 12 days.",
                  latex: "$$1.20 \\times 12 = 14.4$$"
                },
                {
                  text: "Step 2: Subtract the total growth from the final height.",
                  latex: "$$36.8 - 14.4 = 22.4$$"
                },
                {
                  text: "Conclusion: The height of the plant at the beginning of the study was $$22.4$$ cm, matching option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "79cf8505",
        type: "multiple-choice",
        question: "A line segment that has a length of $$115$$ cm is divided into three parts. One part is $$47$$ cm long. The other two parts have lengths that are equal to each other. What is the length, in cm, of one of the other two parts of equal length?",
        options: ["$$34$$", "$$47$$", "$$68$$", "$$23$$"],
        correctAnswerIndex: 0,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To find the length of each equal part, subtract the given segment's length and divide the remaining length equally."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract the length of the known part.",
                  latex: "$$115 - 47 = 68$$"
                },
                {
                  text: "Step 2: Divide the remaining length equally between the two parts.",
                  latex: "$$68 \\div 2 = 34$$"
                },
                {
                  text: "Conclusion: The length of each equal part is $$34$$ cm, matching option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "620abf36",
        type: "multiple-choice",
        question: "If $$5(x + 4) = 4(x + 4) + 29$$, what is the value of $$x + 4$$?",
        options: ["$$-4$$", "$$25$$", "$$29$$", "$$33$$"],
        correctAnswerIndex: 2,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To solve for $$x + 4$$, simplify and isolate the expression on one side of the equation."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$4(x + 4)$$ from both sides.",
                  latex: "$$5(x + 4) - 4(x + 4) = 29$$"
                },
                {
                  text: "Step 2: Simplify the left-hand side.",
                  latex: "$$(x + 4) = 29$$"
                },
                {
                  text: "Conclusion: The value of $$x + 4$$ is $$29$$, matching option C."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "4f669597",
        type: "multiple-choice",
        question: "What value of $$p$$ is the solution to the equation $$2(p + 1) + 8(p - 1) = 5p$$?",
        options: ["$$1.2$$", "$$0.6$$", "$$-1.2$$", "$$2.4$$"],
        correctAnswerIndex: 0,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To solve for $$p$$, distribute, combine like terms, and isolate $$p$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Distribute the terms inside the parentheses.",
                  latex: "$$2p + 2 + 8p - 8 = 5p$$"
                },
                {
                  text: "Step 2: Combine like terms on the left-hand side.",
                  latex: "$$10p - 6 = 5p$$"
                },
                {
                  text: "Step 3: Subtract $$5p$$ from both sides.",
                  latex: "$$5p - 6 = 0$$"
                },
                {
                  text: "Step 4: Solve for $$p$$.",
                  latex: "$$p = \\frac{6}{5} = 1.2$$"
                },
                {
                  text: "Conclusion: The solution is $$p = 1.2$$, matching option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "ce314070",
        type: "multiple-choice",
        question: "If $$4x - \\frac{1}{2} = -5$$, what is the value of $$8x - 1$$?",
        options: ["$$2$$", "$$\\frac{9}{8}$$", "$$\\frac{-5}{2}$$", "$$-10$$"],
        correctAnswerIndex: 3,
        difficulty_level: 2,
        explanation: [
          {
            type: "text",
            data: "To find $$8x - 1$$, solve for $$x$$ first, and then substitute the value into the expression."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Multiply the entire equation by 2 to eliminate the fraction.",
                  latex: "$$2(4x - \\frac{1}{2}) = 2(-5)$$"
                },
                {
                  text: "Step 2: Simplify both sides.",
                  latex: "$$8x - 1 = -10$$"
                },
                {
                  text: "Conclusion: The value of $$8x - 1$$ is $$-10$$, matching option D."
                }
              ]
            }
          }
        ]
      },


      



















      //EASY LAND 
      {
        associatedID: "5c94e6fa",
        type: "multiple-choice",
        question: "In the given equation, $$k$$ is a constant. The equation has infinitely many solutions. What is the value of $$k$$ in $$3x + 21 = 3x + k$$?",
        options: ["21", "22", "23", "24"],
        correctAnswerIndex: 0,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "For an equation to have infinitely many solutions, both sides must be identical for all values of $$x$$. This requires the constant terms on both sides to be equal."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$3x$$ from both sides to eliminate the variable term.",
                  latex: "$$3x + 21 - 3x = 3x + k - 3x$$"
                },
                {
                  text: "Step 2: Simplify the equation to isolate $$k$$.",
                  latex: "$$21 = k$$"
                },
                {
                  text: "Conclusion: Since both sides must match, $$k = 21$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "6105234d",
        type: "multiple-choice",
        question: "John paid a total of $$165$$ for a microscope by making a down payment of $$37$$ plus $$p$$ monthly payments of $$16$$ each. Which equation represents this situation?",
        options: [
          "$$16p - 37 = 165$$",
          "$$37p - 16 = 165$$",
          "$$16p + 37 = 165$$",
          "$$37p + 16 = 165$$"
        ],
        correctAnswerIndex: 2,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To model the situation, the total payment includes the down payment and the amount from monthly payments. This can be written as $$16p + 37 = 165$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Represent the monthly payments as $$16p$$.",
                  latex: "$$16p$$"
                },
                {
                  text: "Step 2: Add the down payment $$37$$ to the monthly payments.",
                  latex: "$$16p + 37$$"
                },
                {
                  text: "Step 3: Set the total equal to $$165$$.",
                  latex: "$$16p + 37 = 165$$"
                },
                {
                  text: "Conclusion: The equation representing this situation is $$16p + 37 = 165$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "349a5bc1",
        type: "multiple-choice",
        question: "If $$4x + 5 = 165$$, what is the solution to the equation?",
        options: ["40", "41", "42", "43"],
        correctAnswerIndex: 0,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To solve for $$x$$, isolate $$4x$$ and divide to find the value of $$x$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$5$$ from both sides to isolate $$4x$$.",
                  latex: "$$4x = 165 - 5$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$4x = 160$$"
                },
                {
                  text: "Step 3: Divide both sides by $$4$$.",
                  latex: "$$x = \\frac{160}{4} = 40$$"
                },
                {
                  text: "Conclusion: The solution to the equation is $$x = 40$$, which matches option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "51aabd93",
        type: "multiple-choice",
        question: "If $$(p + 3) + 8 = 10$$, what is the value of $$p$$?",
        options: ["$$-1$$", "$$5$$", "$$15$$", "$$21$$"],
        correctAnswerIndex: 0,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To solve for $$p$$, isolate $$p$$ by simplifying the equation."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$8$$ from both sides.",
                  latex: "$$(p + 3) = 10 - 8$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$p + 3 = 2$$"
                },
                {
                  text: "Step 3: Subtract $$3$$ from both sides.",
                  latex: "$$p = 2 - 3$$"
                },
                {
                  text: "Conclusion: The solution is $$p = -1$$."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "f2b63f49",
        type: "multiple-choice",
        question: "What is the value of $$x$$ in $$8x - 7x + 130 = 260$$?",
        options: ["120", "125", "130", "135"],
        correctAnswerIndex: 2,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To solve for $$x$$, combine like terms and isolate $$x$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Combine like terms on the left-hand side.",
                  latex: "$$8x - 7x = x$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$x + 130 = 260$$"
                },
                {
                  text: "Step 3: Subtract $$130$$ from both sides.",
                  latex: "$$x = 260 - 130$$"
                },
                {
                  text: "Conclusion: The solution to the equation is $$x = 130$$."
                }
              ]
            }
          }
        ]
      },

      {
        associatedID: "097e10f5",
        type: "multiple-choice",
        question: "What value of $$p$$ satisfies the equation $$5p + 180 = 250$$?",
        options: ["14", "65", "86", "250"],
        correctAnswerIndex: 0,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To find the value of $$p$$, we isolate it by first removing the constant and then dividing by the coefficient of $$p$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$180$$ from both sides to isolate $$5p$$.",
                  latex: "$$5p = 250 - 180$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$5p = 70$$"
                },
                {
                  text: "Step 3: Divide both sides by $$5$$ to solve for $$p$$.",
                  latex: "$$p = \\frac{70}{5} = 14$$"
                },
                {
                  text: "Conclusion: The value of $$p$$ is $$14$$, which matches option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "baca4a4c",
        type: "multiple-choice",
        question: "Which equation has the same solution as $$7(2x - 3) = 63$$?",
        options: [
          "$$2x - 3 = 9$$",
          "$$2x - 3 = 56$$",
          "$$2x - 21 = 63$$",
          "$$2x - 21 = 70$$"
        ],
        correctAnswerIndex: 0,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To determine which equation has the same solution, simplify the original equation by dividing both sides by $$7$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Divide both sides of the equation by $$7$$.",
                  latex: "$$\\frac{7(2x - 3)}{7} = \\frac{63}{7}$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$2x - 3 = 9$$"
                },
                {
                  text: "Conclusion: The equivalent equation is $$2x - 3 = 9$$, which matches option A."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "0adbe034",
        type: "multiple-choice",
        question: "If $$4x - 28 = -24$$, what is the value of $$x - 7$$?",
        options: ["$$-24$$", "$$-22$$", "$$-6$$", "$$-1$$"],
        correctAnswerIndex: 2,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To solve for $$x - 7$$, simplify the equation step by step by isolating $$x$$ and substituting to find $$x - 7$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Add $$28$$ to both sides to isolate $$4x$$.",
                  latex: "$$4x = -24 + 28$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$4x = 4$$"
                },
                {
                  text: "Step 3: Divide both sides by $$4$$ to find $$x$$.",
                  latex: "$$x = \\frac{4}{4} = 1$$"
                },
                {
                  text: "Step 4: Subtract $$7$$ from $$x$$ to find $$x - 7$$.",
                  latex: "$$x - 7 = 1 - 7 = -6$$"
                },
                {
                  text: "Conclusion: The value of $$x - 7$$ is $$-6$$, which matches option C."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "fa80893a",
        type: "multiple-choice",
        question: "If $$2x + 3 = 9$$, what is the value of $$6x - 1$$?",
        options: ["15", "16", "17", "18"],
        correctAnswerIndex: 2,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To find $$6x - 1$$, solve for $$x$$ first and then substitute the value into the expression $$6x - 1$$."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Subtract $$3$$ from both sides to isolate $$2x$$.",
                  latex: "$$2x = 9 - 3$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$2x = 6$$"
                },
                {
                  text: "Step 3: Divide both sides by $$2$$ to find $$x$$.",
                  latex: "$$x = \\frac{6}{2} = 3$$"
                },
                {
                  text: "Step 4: Substitute $$x = 3$$ into $$6x - 1$$.",
                  latex: "$$6x - 1 = 6(3) - 1 = 18 - 1 = 17$$"
                },
                {
                  text: "Conclusion: The value of $$6x - 1$$ is $$17$$, which matches option C."
                }
              ]
            }
          }
        ]
      },
      {
        associatedID: "590f2187",
        type: "multiple-choice",
        question: "If $$3x - 27 = 24$$, what is the value of $$x - 9$$?",
        options: ["1", "8", "24", "35"],
        correctAnswerIndex: 1,
        difficulty_level: 1,
        explanation: [
          {
            type: "text",
            data: "To solve for $$x - 9$$, simplify the equation to find $$x$$ first and then subtract $$9$$ from it."
          },
          {
            type: "steps",
            data: {
              steps: [
                {
                  text: "Step 1: Add $$27$$ to both sides to isolate $$3x$$.",
                  latex: "$$3x = 24 + 27$$"
                },
                {
                  text: "Step 2: Simplify the equation.",
                  latex: "$$3x = 51$$"
                },
                {
                  text: "Step 3: Divide both sides by $$3$$ to find $$x$$.",
                  latex: "$$x = \\frac{51}{3} = 17$$"
                },
                {
                  text: "Step 4: Subtract $$9$$ from $$x$$ to find $$x - 9$$.",
                  latex: "$$x - 9 = 17 - 9 = 8$$"
                },
                {
                  text: "Conclusion: The value of $$x - 9$$ is $$8$$, which matches option B."
                }
              ]
            }
          }
        ]
      }

    
       
      





























    ]
  
    
    
    

  }        