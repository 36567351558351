//Linear functions

export const linearEqTwoVars
= {
    title: "Linear equations in two variables",
    order: 3,
    locked: false, 
    unlockDate: null,
    lessons: [],
       

}