import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For web navigation
import { getCurrentChapterForUser } from '../firebase/firebaseUtils';
import useAuth from '../hooks/useAuth';

const Course = ({ courseId, title, subtitle, imageUrl, locked, data }) => {
  const navigate = useNavigate(); // For web navigation
  const { userId } = useAuth();  // Get the userId from the useAuth hook
  const [chapterId, setChapterId] = useState(null); // Track the user's current chapter

  // Fetch the user's current chapter when the component mounts
  useEffect(() => {
    const fetchCurrentChapter = async () => {
      if (!userId || !courseId) {
        return;
      }

      try {
        const chapter = await getCurrentChapterForUser(userId, courseId);
        console.log('Current chapter:', chapter);
        setChapterId(chapter);  // Store the current chapterId
      } catch (error) {
        console.error('Error fetching current chapter:', error);
      }
    };

    fetchCurrentChapter();
  }, [userId, courseId]);

  // go to lessons screen 
  const handleNavigateToLessons = () => {
    // Log chapterId and courseId first
    console.log("Chapter ID:", chapterId);
    console.log("Course ID:", courseId);

    // Check conditions and navigate if appropriate
    if (chapterId && !locked) {
        navigate(`/lessons/${courseId}/${chapterId}`); // Navigate to the lesson
    } else {
        console.error('Course is locked or no current chapter available');
    }
};

  return (
    <div 
      className="bg-white rounded-lg shadow-md p-6 mb-6 w-full md:w-1/2 lg:w-1/3 mx-auto flex flex-col items-center justify-center relative overflow-hidden"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '300px',
        minWidth: '280px', // Set a minimum width for small screens
        maxWidth: '600px'  // Set a maximum width for large screens
      }}
    >
      {/* Apply a blurred background effect */}
      <div 
        className="absolute inset-0 bg-white bg-opacity-30 backdrop-blur-md"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(10px)' // Extra filter for fine control
        }}
      />
      
      {/* Content inside */}
      <div className="relative text-center z-10">
        <h2 className="text-xl font-bold text-white mb-2">{title}</h2>
        <p className="text-white text-sm mb-4">{subtitle}</p>
        
        {/* Conditionally render based on locked status */}
        {locked ? (
          <button
            className="bg-gray-300 text-gray-700 border border-gray-400 cursor-not-allowed rounded-md py-2 px-4"
            disabled
          >
            Coming Soon
          </button>
        ) : (
          <button
            className="bg-white text-black border border-gray-300 hover:bg-gray-100 rounded-md py-2 px-4"
            onClick={handleNavigateToLessons}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default Course;
