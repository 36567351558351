//Linear functions

export const linearInequalitiesOneOrTwoVars= {
    title: "Linear inequalities in one or two variables",
    order: 5,
    locked: false, 
    unlockDate: null,
    lessons: [],
       

}