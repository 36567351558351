//display list of lessons 
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';  

import LessonContainer from '../components/LessonContainer';
import { useAuth } from '../contexts/AuthContext';  // Updated to match previous conversions
import Navbar from '../components/Navbar';  // Import Navbar component

// Temporary Functions (Delete Later)
import {
  uploadCourseWithAutoIds,
  setCurrentChapterForUser,
} from '../firebase/firebaseUtils';

const LessonsScreen = () => {
  const [chapterTitle, setChapterTitle] = useState('');  // Store the chapter title
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);  // Manages the overall loading state

  const { courseId, chapterId } = useParams();  // Get courseId and chapterId from URL params
  const { currentUser } = useAuth();  // Get userId and loading state from useAuth hook
  const navigate = useNavigate();  // Use navigate hook to navigate to ChaptersScreen

  // Refs to Prevent Multiple Fetches
  const hasFetchedChapter = useRef(false);
  const hasFetchedLessons = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!courseId || !chapterId) {
        console.warn('courseId or chapterId is missing');
        setLoading(false);
        return;
      }

      try {
        // Fetch Chapter Details
        if (!hasFetchedChapter.current) {
          const chapterRef = doc(firestore, 'courses', courseId, 'chapters', chapterId);
          const chapterDoc = await getDoc(chapterRef);

          if (chapterDoc.exists()) {
            const chapterData = chapterDoc.data();
            setChapterTitle(chapterData.title || 'Lessons');  // Set the chapter title
          } else {
            console.error('Chapter not found');
            setChapterTitle('Lessons');
          }

          hasFetchedChapter.current = true;
        }

        // Fetch Lessons
        if (!hasFetchedLessons.current) {
          const lessonsRef = collection(firestore, `courses/${courseId}/chapters/${chapterId}/lessons`);
          const lessonsSnapshot = await getDocs(lessonsRef);
          const fetchedLessons = lessonsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Sort lessons by their 'order' attribute before setting them in state
          const sortedLessons = fetchedLessons.sort((a, b) => a.order - b.order);
          
          setLessons(sortedLessons);
          hasFetchedLessons.current = true;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);  // Stop loading after data is fetched
      }
    };

    fetchData();
  }, [courseId, chapterId]);  // Re-run this effect when courseId or chapterId changes

  const handleSeeAllChapters = () => {
    navigate(`/chapters/${courseId}`);  // Navigate to ChaptersScreen with the courseId
  };

  // Temporary Functions (Delete Later)
  const handleUploadCourse = async () => {
    try {
      await uploadCourseWithAutoIds(); // Ensure this function is properly implemented
      window.alert('Course uploaded successfully!');
    } catch (error) {
      console.error('Error uploading course:', error);
      window.alert('There was an error uploading the course.');
    }
  };

  // Handler to Set Current Chapter for the User
  const handleSetCurrentChapter = async () => {
    if (!currentUser) {
      window.alert('User is not authenticated.');
      return;
    }

    try {
      await setCurrentChapterForUser(currentUser.uid, courseId, chapterId);
      window.alert('Current chapter updated successfully!');
    } catch (error) {
      console.error('Failed to update current chapter:', error);
      window.alert('Failed to update current chapter. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Navbar */}
      <Navbar />
      {/* Main Content */}
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold text-gray-800 mb-4 text-center">{chapterTitle}</h1>
        
        {/* "See all chapters" Button */}
        <div className="flex justify-center mb-6">
          <button
            onClick={handleSeeAllChapters}
            className="text-grey-500 hover:underline font-medium"
          >
            See all chapters ayye
          </button>
        </div>

        {loading ? (
          <div className="flex justify-center items-center">
            <p className="text-gray-600">Loading lessons...</p>
          </div>
        ) : (
          <div>
            {lessons.length > 0 ? (
              <LessonContainer lessons={lessons} courseId={courseId} chapterId={chapterId} />
            ) : (
              <div className="text-center">
                <p className="text-gray-600">No lessons available for this chapter.</p>
              </div>
            )}
          </div>
        )}
        {/* <div className="mt-8 flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
          <button
            onClick={handleUploadCourse}
            className="bg-green-500 hover:bg-green-600 text-white font-sans py-2 px-4 rounded-md transition-colors duration-200"
          >
            Upload Course with Auto IDs
          </button>
          <button
            onClick={handleSetCurrentChapter}
            className="bg-blue-500 hover:bg-blue-600 text-white font-sans py-2 px-4 rounded-md transition-colors duration-200"
          >
            Set Current Chapter
          </button>
        </div> */}
      </main>
    </div>
  );
};

export default LessonsScreen;
