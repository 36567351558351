// src/App.js

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './screens/Signup';
import Login from './screens/Login';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import LessonsScreen from './screens/LessonsScreen';
import LessonContentScreen from './screens/LessonContentScreen';
import PracticeScreen from './screens/PracticeScreen';
import ChaptersScreen from './screens/ChaptersScreen';
import AboutScreen from './screens/AboutScreen';
import UpgradeScreen from './screens/UpgradeScreen';
import SuccessScreen from './screens/SuccessScreen';
import CancelScreen from './screens/CancelScreen';
import Subscribe from './components/Subscribe';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ComingSoon from './screens/ComingSoon';
import LandingPage from './components/LandingPage'; // Import LandingPage
import CancelSubscriptionScreen from './screens/CancelSubscriptionScreen';
import LoginSelection from './screens/login/LoginSelection';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function App() {
  return (
    <Router>
      <AuthProvider>
        <Suspense
          fallback={
            <div className="flex items-center justify-center h-screen">
              <p className="text-lg text-gray-700">Loading...</p>
            </div>
          }
        >
       
            <Routes>
              <Route path="/login-selection" element={<LoginSelection />} />
            <Route path="/cancel-subscription" element={<CancelSubscriptionScreen />} />
              <Route path="/comingsoon" element={<ComingSoon />} />
              <Route path="/about" element={<AboutScreen />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/success" element={<SuccessScreen />} />
              <Route path="/cancel" element={<CancelScreen />} />
              <Route path="/upgrade" element={<UpgradeScreen />} />

              {/* Root Route */}
              <Route path="/" element={<LandingPage />} />

              {/* Protected Routes */}
              <Route
                path="/chapters/:courseId"
                element={
                  <PrivateRoute>
                    <ChaptersScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/lessons/:courseId/:chapterId"
                element={
                  <PrivateRoute>
                    <LessonsScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/lesson-content/:courseId/:chapterId/:lessonId"
                element={
                  <PrivateRoute>
                    <LessonContentScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/practice/:courseId/:chapterId/:lessonId"
                element={
                  <PrivateRoute>
                    <PracticeScreen />
                  </PrivateRoute>
                }
              />

              {/* 404 Page */}
              <Route
                path="*"
                element={
                  <div className="flex items-center justify-center h-screen">
                    <h1 className="text-3xl font-bold">404 - Page Not Found</h1>
                  </div>
                }
              />
            </Routes>
   
        </Suspense>
      </AuthProvider>
    </Router>
  );
}

export default App;
