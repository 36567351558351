// for the sat course, these are the chapters that will be used.
   //so we will have around 25 imports for each lesson.

   import { linearEqOneVar } from "./sat/chapters/algebra/lesson1";
   import { linearFunctions } from "./sat/chapters/algebra/lesson2";
   import { linearEqTwoVars } from "./sat/chapters/algebra/lesson3";
   import { systemsLinearEqTwoVars } from "./sat/chapters/algebra/lesson4";
  import { linearInequalitiesOneOrTwoVars } from "./sat/chapters/algebra/lesson5";
   //satChapters holds multiple chapters, each chapter has multiple lessons.
export const satChapters = [
      
  //only chapter being tested right now.
    { 
      title: "Algebra",
      order: 1,
      locked: false, 
      unlockDate: null,
      //no delete, modify chapter yes, modify lesson yes. 
      lessons: [
     
        linearEqOneVar, //first lesson 
        linearFunctions,
        linearEqTwoVars,
        systemsLinearEqTwoVars,
        linearInequalitiesOneOrTwoVars,
      
    ]
},










    {//chapter 
      title: "Advanced math",
      order: 2,
      locked: true,
      unlockDate: "2024-11-04",
      lessons: [
        {
          title: "Introduction to CSS",
          order: 1,
          content: [
            {
              type: "text",
              data: "CSS is used to style HTML elements."
            },
            {
              type: "image",
              data: "https://example.com/css-introduction-image.jpg"
            },
            {
              type: "multiple-choice",
              data: {
                question: "What does CSS stand for?",
                options: ["Cascading Style Sheets", "Creative Style Sheets", "Colorful Style Sheets"],
                correctAnswer: "Cascading Style Sheets"
              }
            }
          ]
        }
      ]
    },

    {
      title: "Advanced Math",
      order: 3,
      locked: true,
      unlockDate: "2024-11-11",
      lessons: []
    },
    {
      title: "Advanced Math",
      order: 4,
      locked: true,
      unlockDate: "2024-11-18",
      lessons: []
    },
    {
      title: "Geometry and Trigonometry",
      order: 5,
      locked: true,
      unlockDate: "2024-11-25",
      lessons: []
    },
  ];


