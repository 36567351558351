
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment, setDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Adjust the import according to your firebase setup
import { useAuth } from '../contexts/AuthContext'; // Get currentUser
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';
import { storeCompletedLesson } from '../firebase/firebaseUtils'; // Import the function
import { ArrowLeft, CheckCircle, XCircle, ChevronRight, Trophy } from 'lucide-react';
import { MathJaxContext, MathJax } from "better-react-mathjax";
import { uploadCourseWithAutoIds } from '../firebase/firebaseUtils';

const PracticeScreen = () => {
  const { courseId, chapterId, lessonId } = useParams(); // Get route parameters
  const { currentUser } = useAuth(); // Get user information
  const navigate = useNavigate(); // For navigation
  const [practiceQuestions, setPracticeQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  // New state variables
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentDifficultyLevel, setCurrentDifficultyLevel] = useState(1); // Start at Easy
  const [correctInRow, setCorrectInRow] = useState(0);
  const [incorrectInRow, setIncorrectInRow] = useState(0);
  const [askedQuestionsIds, setAskedQuestionsIds] = useState([]);
  const [questionsByDifficulty, setQuestionsByDifficulty] = useState({});

  const [userAnswer, setUserAnswer] = useState(null);
  const [correctAnswered, setCorrectAnswered] = useState(false); // Track if the correct answer is selected
  const [progress, setProgress] = useState(0); // Track progress
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // Track selected option
  const [isChecked, setIsChecked] = useState(false); // Track if the answer has been checked
  const [isFinished, setIsFinished] = useState(false); // Track if user has finished all questions
  const [showExplanation, setShowExplanation] = useState(false);

  const handleUploadCourse = async () => {
    try {
      await uploadCourseWithAutoIds(); // Ensure this function is properly implemented
      window.alert('Course uploaded successfully!');
    } catch (error) {
      console.error('Error uploading course:', error);
      window.alert('There was an error uploading the course.');
    }
  };

  const handleSkipQuestion = () => {
    handleNextQuestion();
  };

  useEffect(() => {
    const loadPracticeQuestions = async () => {
      if (!lessonId) {
        console.warn('lessonId is missing');
        setLoading(false);
        return;
      }
  
      try {
        const lessonRef = doc(
          firestore,
          'courses',
          courseId,
          'chapters',
          chapterId,
          'lessons',
          lessonId
        );
        const lessonDoc = await getDoc(lessonRef);
  
        if (lessonDoc.exists()) {
          const lessonData = lessonDoc.data();
          setPracticeQuestions(lessonData.practice || []);
  
          // Categorize questions by difficulty level
          const questionsByDifficulty = {
            1: (lessonData.practice || []).filter(q => q.difficulty_level === 1),
            2: (lessonData.practice || []).filter(q => q.difficulty_level === 2),
            3: (lessonData.practice || []).filter(q => q.difficulty_level === 3),
          };
          setQuestionsByDifficulty(questionsByDifficulty);
  
          // Set the first question from Easy level
          const firstQuestion = getNextQuestion(1, [], questionsByDifficulty);
          setCurrentQuestion(firstQuestion);
          setAskedQuestionsIds([firstQuestion.associatedID]);
  
          // Initialize lessonProgress.correctAnswers to 0 if it doesn't exist
          if (currentUser) {
            const lessonProgressRef = doc(
              firestore,
              'users',
              currentUser.uid,
              'progress',
              courseId,
              'lessonProgress',
              lessonId
            );
  
            const lessonProgressDoc = await getDoc(lessonProgressRef);
            if (!lessonProgressDoc.exists()) {
              await setDoc(lessonProgressRef, {
                correctAnswers: 0
              });
              setCorrectAnswers(0);
            } else {
              const data = lessonProgressDoc.data();
              setCorrectAnswers(data.correctAnswers || 0);
            }
            
          }
        } else {
          console.error('Lesson not found');
        }
      } catch (error) {
        console.error('Error fetching practice questions:', error);
        toast.error('Failed to load practice questions.');
      } finally {
        setLoading(false);
      }
    };
  
    loadPracticeQuestions();
  }, [courseId, chapterId, lessonId, currentUser]);
  




  // Function to get the next question based on current difficulty level
  const getNextQuestion = (
    difficultyLevel = currentDifficultyLevel,
    askedIds = askedQuestionsIds,
    qByDiff = questionsByDifficulty
  ) => {
    const questionsArray = qByDiff[difficultyLevel] || [];
    const availableQuestions = questionsArray.filter(
      q => !askedIds.includes(q.associatedID)
    );

    if (availableQuestions.length === 0) {
      // No more questions at this difficulty level
      return null;
    }

    const nextQuestion =
      availableQuestions[Math.floor(Math.random() * availableQuestions.length)];
    return nextQuestion;
  };

  const handleAnswer = index => {
    setSelectedOptionIndex(index); // Set the selected option index
    setIsChecked(false); // Reset check state when user changes selection
    setUserAnswer(null); // Reset previous answer feedback
  };

  const handleCheckAnswer = async () => { // Changed to async
    if (selectedOptionIndex === null) {
      toast.warn('Please select an option before checking.');
      return;
    }
  
    const correctAnswerIndex = currentQuestion.correctAnswerIndex;
  
    if (selectedOptionIndex === correctAnswerIndex) {
      setUserAnswer('Correct!');
      setCorrectAnswered(true); // Enable continue button
  
      // Update correctInRow and reset incorrectInRow
      setCorrectInRow(prev => prev + 1);
      setIncorrectInRow(0);
  
      // Update the correctAnswers count in Firebase
      if (currentUser) {
        try {
          const lessonProgressRef = doc(
            firestore,
            'users',
            currentUser.uid,
            'progress',
            courseId,
            'lessonProgress',
            lessonId
          );
  
         await setDoc(lessonProgressRef, {
  correctAnswers: increment(1)
}, { merge: true });
  
          toast.success('Your correct answer count has been updated!');
        } catch (error) {
          console.error('Error updating correctAnswers:', error);
          toast.error('Failed to update your progress. Please try again.');
        }
      } else {
        toast.error('User not authenticated.');
      }
    } else {
      setUserAnswer('Incorrect, try again.');
      setCorrectAnswered(false); // Disable continue button
  
      // Update incorrectInRow and reset correctInRow
      setIncorrectInRow(prev => prev + 1);
      setCorrectInRow(0);
    }
    setIsChecked(true); // Mark as checked after checking
  };
  
  





  // Adjust difficulty level based on user's performance
  useEffect(() => {
    adjustDifficultyLevel();
  }, [correctInRow, incorrectInRow]);

  const adjustDifficultyLevel = () => {
    if (currentDifficultyLevel === 1) {
      // Easy Level
      if (correctInRow >= 2) {
        setCurrentDifficultyLevel(2); // Move to Medium
        setCorrectInRow(0);
      }
      // No action on incorrect answers at Easy level
    } else if (currentDifficultyLevel === 2) {
      // Medium Level
      if (correctInRow >= 5) {
        setCurrentDifficultyLevel(3); // Move to Hard
        setCorrectInRow(0);
      } else if (incorrectInRow >= 2) {
        setCurrentDifficultyLevel(1); // Move back to Easy
        setIncorrectInRow(0);
      }
    } else if (currentDifficultyLevel === 3) {
      // Hard Level
      if (incorrectInRow >= 2) {
        setCurrentDifficultyLevel(2); // Move back to Medium
        setIncorrectInRow(0);
      }
      // No action on correct answers at Hard level
    }
  };

  const handleNextQuestion = () => {
    // Reset states
    setUserAnswer(null);
    setCorrectAnswered(false);
    setSelectedOptionIndex(null); // Reset the selected option index
    setIsChecked(false); // Reset check state
    setShowExplanation(false); // Hide explanation for the next question

    // Get the next question
    const nextQuestion = getNextQuestion();

    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
      setAskedQuestionsIds(prevIds => [...prevIds, nextQuestion.associatedID]);

      // Update progress (optional)
      setProgress(
        askedQuestionsIds.length / practiceQuestions.length
      ); // Update progress
    } else {
      // No more questions at this difficulty level
      setIsFinished(true);
      setProgress(1); // Complete the progress bar
      handleCompleteLesson(); // Call the function to mark lesson as completed
    }
  };

  // Function to handle marking the lesson as completed
  const handleCompleteLesson = async () => {
    try {
      if (!currentUser) {
        throw new Error('User is not authenticated');
      }
      await storeCompletedLesson(currentUser.uid, courseId, lessonId);
      // Optionally, display a success message
      toast.success(`Lesson ${lessonId} marked as completed!`);
    } catch (error) {
      console.error('Error marking lesson as completed:', error);
      toast.error('Failed to mark lesson as completed. Please try again.');
    }
  };

  const handleToggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  // Helper function to render text with line breaks and math
  const renderTextWithMath = (text) => {
    // Split by $$ instead of single $
    const parts = text.split(/\$\$(.+?)\$\$/g);
    
    return parts.map((part, idx) => {
      if (idx % 2 === 1) {
        // Render LaTeX
        return (
          <MathJaxContext>
<MathJax key={idx} inline hideUntilTypeset="first">
            {`\\(${part}\\)`}
          </MathJax>
          </MathJaxContext>
          
        );
      } else {
        // Render regular text with line breaks
        return (
          <span key={idx}>
            {part.split('\n').map((line, lineIndex) => (
              <React.Fragment key={lineIndex}>
                {line}
                {lineIndex < part.split('\n').length - 1 && <br />}
              </React.Fragment>
            ))}
          </span>
        );
      }
    });
  };

  const renderExplanation = contentItem => {
    switch (contentItem.type) {
      case 'subtitle':
        return (
          <h2 key={contentItem.data} className="text-2xl font-semibold mb-3">
            {contentItem.data}
          </h2>
        );

      // This will be used to display the text in the explanation
      case 'text':
        return (
          <p key={contentItem.data} className="text-base text-gray-700 mb-4">
            {renderTextWithMath(contentItem.data)}
          </p>
        );

      // Whenever we want to display LaTeX
      case 'latex':
        return (
          <MathJaxContext>
            <div
              key={contentItem.data}
              className="flex justify-center items-center w-full mb-6"
            >
              {/* Added bottom margin */}
              <MathJax hideUntilTypeset={'first'} inline={false}>
                {contentItem.data}
              </MathJax>
            </div>
          </MathJaxContext>
        );

      case 'steps':
        return (
          <div key={contentItem.data.title} className="mb-8">
            {/* Increased bottom margin */}
            <ol className="list-none mb-4 space-y-4">
              {/* Adds space between each list item */}
              {contentItem.data.steps.map((step, index) => (
                <li key={index} className="mb-4">
                  {/* Increased bottom margin */}
                  <p className="mb-2">{renderTextWithMath(step.text)}</p>
                  {/* Adds space below text */}
                  {step.latex && (
                    <MathJaxContext>
                      <div className="flex justify-center items-center w-full my-4">
                        {/* Margin added above and below MathJax */}
                        <MathJax hideUntilTypeset={'first'} inline={false}>
                          {step.latex}
                        </MathJax>
                      </div>
                    </MathJaxContext>
                  )}
                </li>
              ))}
            </ol>
            {contentItem.data.conclusion && (
              <p className="text-base italic text-gray-600 mt-4">
                {renderTextWithMath(contentItem.data.conclusion)}
              </p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  if (isFinished) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
        <div className="max-w-2xl mx-auto pt-20 px-4">
          <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
            <div className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <Trophy className="w-10 h-10 text-green-600" />
            </div>
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              Congratulations!
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              You've successfully completed all practice questions.
            </p>
            <button
              onClick={handleGoBack}
              className="inline-flex items-center px-6 py-3 text-white bg-blue-600 rounded-xl hover:bg-blue-700 transition-all duration-200 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Return to Lessons
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 flex-1 overflow-y-auto mb-24">
        {/* Skip Question and Upload Course Buttons */}
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSkipQuestion}
            className="mt-4 px-4 py-2 text-white bg-blue-600 rounded-xl hover:bg-blue-700 transition-all duration-200"
          >
            Skip to Next
          </button>
          <button
            onClick={handleUploadCourse}
            className="mt-4 px-4 py-2 bg-green-500 text-white rounded-xl hover:bg-green-700 transition-all duration-200"
          >
            Upload Course
          </button>
        </div>

        {/* Progress Bar */}
        <div className="flex items-center mb-6">
          <button
            onClick={handleGoBack}
            className="mr-4 text-gray-400 hover:text-gray-600 transition-colors duration-200"
            aria-label="Close Practice Session"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="w-full bg-gray-300 rounded-full h-4">
            <div
              className="bg-green-500 h-4 rounded-full transition-all duration-300 ease-out"
              style={{ width: `${progress * 100}%` }}
            ></div>
          </div>
        </div>

        {loading ? (
          <div className="flex items-center justify-center min-h-[60vh]">
            {/* Loading Skeleton */}
          </div>
        ) : currentQuestion ? (
          <div className={`${showExplanation ? 'flex space-x-6' : ''}`}>
            {/* Left Side - Explanation */}
            {showExplanation && (
              <div className="w-1/2 bg-white p-6 ">
                {currentQuestion.explanation ? (
                  currentQuestion.explanation.map((contentItem, idx) => (
                    <React.Fragment key={idx}>
                      {renderExplanation(contentItem)}
                    </React.Fragment>
                  ))
                ) : (
                  <p className="text-gray-600">No explanation available.</p>
                )}
              </div>
            )}

            {/* Right Side - Question, Options */}
            <div
              className={`${
                showExplanation ? 'w-1/2 ml-6' : 'w-full max-w-[700px]'
              } space-y-6`}
            >
              <div className="bg-white p-6">
                
                <h2 className="text-xl font-semibold text-gray-900 mb-6 ">
                  {renderTextWithMath(currentQuestion.question)}
                </h2>
                <p className="text-gray-600 mb-4">
                  Difficulty Level:{' '}
                  {currentDifficultyLevel === 1
                    ? 'Easy'
                    : currentDifficultyLevel === 2
                    ? 'Medium'
                    : 'Hard'}
                </p>

                {currentQuestion.imageUrl && (
  <div className="mt-4">
    <img
      src={currentQuestion.imageUrl}
      alt="Question Illustration"
      className="w-full h-auto rounded-lg"
    />
  </div>
)}


                <div className="space-y-3 mt-6">
                  {currentQuestion.options.map((option, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleAnswer(idx)}
                      disabled={correctAnswered}
                      className={`
              w-full text-left p-4 rounded-xl transition-all duration-200
              flex items-center justify-between gap-4
              ${
                selectedOptionIndex === idx
                  ? isChecked
                    ? userAnswer === 'Correct!'
                      ? 'bg-green-100 border-2 border-green-500 text-green-900'
                      : 'bg-red-100 border-2 border-red-500 text-red-900'
                    : 'bg-blue-100 border-2 border-blue-500 text-blue-900'
                  : 'bg-gray-50 border-2 border-gray-200 text-gray-700 hover:bg-gray-100 hover:border-gray-300'
              }
            `}
                    >
                      {/* Use renderTextWithMath for each option */}
                      {renderTextWithMath(option)}

                      {isChecked && selectedOptionIndex === idx && (
                        userAnswer === 'Correct!' ? (
                          <CheckCircle className="w-6 h-6 text-green-600" />
                        ) : (
                          <XCircle className="w-6 h-6 text-red-600" />
                        )
                      )}
                    </button>
                  ))}
                </div>

                {userAnswer && (
                  <div
                    className={`mt-6 p-4 rounded-xl flex items-center gap-3 ${
                      userAnswer === 'Correct!'
                        ? 'bg-green-50 text-green-900'
                        : 'bg-red-50 text-red-900'
                    }`}
                  >
                    {userAnswer === 'Correct!' ? (
                      <CheckCircle className="w-5 h-5 text-green-600" />
                    ) : (
                      <XCircle className="w-5 h-5 text-red-600" />
                    )}
                    <p className="font-medium">{userAnswer}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            <div className="bg-gray-100 p-4 rounded-full mb-4">
              <XCircle className="w-8 h-8 text-gray-400" />
            </div>
            <p className="text-lg text-gray-600">No practice questions available.</p>
            <button
              onClick={handleGoBack}
              className="mt-4 inline-flex items-center px-4 py-2 text-blue-600 hover:text-blue-700"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Return to Lessons
            </button>
          </div>
        )}
      </main>

      {/* Fixed Action Buttons */}
      <footer className="fixed bottom-0 left-0 w-full bg-white py-4 px-6 border-t border-gray-200">
        <div className="flex justify-between items-center max-w-5xl mx-auto">
          <button
            onClick={handleGoBack}
            className="inline-flex items-center px-4 py-2 text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200 transition-all duration-200"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back
          </button>

          <div className="flex space-x-4">
            <button
              onClick={handleToggleExplanation}
              className="inline-flex items-center px-4 py-2 rounded-xl text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Explanation
            </button>

            {correctAnswered ? (
              <button
                onClick={handleNextQuestion}
                className="inline-flex items-center px-6 py-3 rounded-xl text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Continue
                <ChevronRight className="w-5 h-5 ml-2" />
              </button>
            ) : (
              <button
                onClick={handleCheckAnswer}
                disabled={selectedOptionIndex === null}
                className={`inline-flex items-center px-6 py-3 rounded-xl transition-all duration-200 ${
                  selectedOptionIndex === null
                    ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                    : 'bg-green-600 text-white hover:bg-green-700'
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
              >
                Check Answer
              </button>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PracticeScreen;


