// src/firebase.js

import { initializeApp } from 'firebase/app'; // Modular import for Firebase v9
import { getFirestore } from 'firebase/firestore'; // Firestore for v9
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5thk5mxkaKvp9QFFTsLp3UzeHdjUKNSE",
  authDomain: "avantdemy2.firebaseapp.com",
  projectId: "avantdemy2",
  storageBucket: "avantdemy2.appspot.com",
  messagingSenderId: "607425239163",
  appId: "1:607425239163:web:45d1011a9c4d76f72eaade",
  measurementId: "G-V2Q929FSP0"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const firestore = getFirestore(app);
export const provider = new GoogleAuthProvider();

// Initialize Firebase Authentication
export const auth = getAuth(app);

export const functions = getFunctions(app);
