import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles, ArrowLeft, Check } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js'; // Import loadStripe
import { useAuth } from '../contexts/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const UpgradeScreen = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false);

    const benefits = [
        "Unlimited access to the latest, top-tier GPT models from OpenAI",
        "Live Weekly Classes with Expert Tutors (1530+) from Emory University",
        "Unlimited Unique Practice Tests",
        "Access to all content and features (including future Careers, AI-College Essay Writer, and more)"
    ];

    const handleUpgrade = async () => {
        if (!currentUser) {
            console.error('User not logged in');
            return;
        }

        setIsLoading(true);
        try {
            // Determine the mode (test or live)
            const isTestMode = process.env.REACT_APP_STRIPE_MODE === 'test';
            console.log(isTestMode)

            // Choose the appropriate Cloud Function name
            const functionName = isTestMode ? 'createCheckoutSessionTest' : 'createCheckoutSession';

            // Create a reference to the correct function
            const createCheckoutSession = httpsCallable(functions, functionName);

            // Call the Cloud Function to create a checkout session
            const { data } = await createCheckoutSession();
            const { sessionId } = data;

            // Initialize Stripe.js with the correct publishable key
            const stripePromise = loadStripe(
                isTestMode
                    ? 'pk_test_51QE9RTBI16uD9m5z66hMrlpGyTC9ec2Dw7Yu9VkCgOaADitWLGPVbB73CnwV2FgiIvOFzSq9KQqRGVR1nCOgU09c00AiFqUKU2'  // Replace with your test publishable key
                    : 'pk_live_51QE9RTBI16uD9m5zgALRyhK1grstwJPvv83YjsKhNIZSfTr7YrlcbqzbZUoXqcS7R5Yw40CqQH9sug2XqWxtHtGQ00fCW2HzcL'  // Replace with your live publishable key
            );

            const stripe = await stripePromise;

            // Redirect to Stripe Checkout
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error('Stripe Checkout Error:', error);
                // Handle the error appropriately
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // Handle the error appropriately
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-white flex items-center justify-center p-4">
            <div className="w-full max-w-3xl">
                {/* Back Button */}
                <button
                    onClick={() => navigate('/')}
                    className="inline-flex items-center text-gray-500 hover:text-gray-700 mb-8 transition-colors duration-200"
                >
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    Back
                </button>

                {/* Main Content */}
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-medium text-gray-900 mb-4">
                        Ready to get into your dream college?
                    </h1>
                    <p className="text-gray-600 text-lg">
                        Join Premium for a comprehensive study experience
                    </p>
                </div>

                {/* Benefits List */}
                <div className="mb-12 space-y-4">
                    {benefits.map((benefit, index) => (
                        <div key={index} className="flex items-center bg-gray-50 p-4 rounded-lg">
                            <Check className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                            <span className="text-gray-700">{benefit}</span>
                        </div>
                    ))}
                </div>

                {/* Pricing */}
                <div className="text-center mb-8">
                    <p className="text-2xl font-medium text-gray-900">
                        $20<span className="text-gray-500 text-lg font-normal">/month</span>
                    </p>
                    <p className="text-gray-600 mt-2">
                        Includes live classes and 200 AI queries per month
                    </p>
                </div>

                {/* Action Buttons */}
                <div className="space-y-4">
                    <button
                        onClick={handleUpgrade}
                        disabled={isLoading || !currentUser}
                        className={`w-full inline-flex items-center justify-center gap-2 bg-black text-white px-6 py-3 rounded-full font-medium transition-all duration-200
                            ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-gray-800'}`}
                    >
                        {isLoading ? (
                            <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        ) : (
                            <Sparkles className="w-4 h-4" />
                        )}
                        {isLoading ? 'Processing...' : 'Upgrade to Premium'}
                    </button>
                    
                    <p className="text-sm text-gray-500 text-center">
                        30-day money-back guarantee • Cancel anytime
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UpgradeScreen;
