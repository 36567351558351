//Linear functions

export const linearFunctions= 
{
    title: "Linear Functions",
    order: 2,
    locked: false, 
    unlockDate: null,
    practice: [
        {
            associatedID: "90095507",
            type: "multiple-choice",
            question: "The Townsend Realty Group purchased the Glenview Street property and received a 40% discount off the original price along with an additional 20% off the discounted price for purchasing the property in cash. Which of the following best approximates the original price, in dollars, of the Glenview Street property?",
            options: [
              "$350,000",
              "$291,700",
              "$233,300",
              "$175,000"
            ],
            correctAnswerIndex: 1,
            difficulty_level: 3,
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/avantdemy2.firebasestorage.app/o/townsend.png?alt=media&token=a2c2b12d-a6a3-4fc4-a16c-2578f5a2165b',
            explanation: [
              {
                type: "text",
                data: "The correct answer is $291,700. To find the original price, work backwards from the 40% and 20% discounts."
              },
              {
                type: "steps",
                data: {
                  steps: [
                    {
                      text: "Step 1: Calculate the final price after the 20% discount.",
                      latex: "\\[ 140,000 = \\text{Original Price} \\times 0.6 \\times 0.8 \\]"
                    },
                    {
                      text: "Step 2: Solve for the original price.",
                      latex: "\\[ \\text{Original Price} = \\frac{140,000}{0.6 \\times 0.8} = 291,700 \\]"
                    },
                    {
                      text: "Conclusion: The original price is approximately $291,700, which matches option B.",
                      latex: ""
                    }
                  ]
                }
              }
            ]
          },
    ],
   

}