import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, provider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore'; // Import getDoc for reading Firestore
import { firestore } from '../../firebase';

function LoginSelection() {
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user exists in Firestore
      const userRef = doc(firestore, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        // If user does not exist, create a new user document
        await setDoc(userRef, {
          fullName: user.displayName,
          email: user.email,
          createdAt: new Date(),
          freeTrialEndDate: null, // Initialize trial to null
          isPremium: false, // Default to non-premium
        });

        // Redirect to the /upgrade screen for a new user
        navigate('/upgrade');
      } else {
        // If user exists, check their freeTrialEndDate and isPremium status
        const userData = userSnap.data();
        const freeTrialEndDate = userData.freeTrialEndDate
          ? new Date(userData.freeTrialEndDate)
          : null;
        const isPremium = userData.isPremium;
        console.log(freeTrialEndDate, isPremium);

    

        // Redirect based on the user's status
        if ((freeTrialEndDate) || isPremium) {
          // If free trial is active or user is premium, redirect to /
          navigate('/');
        } else {
          // If no trial or premium access, redirect to /upgrade
          navigate('/upgrade');
        }
      }
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      alert('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <h1 className="text-3xl font-bold mb-4">Avantdemy</h1>
      <p className="text-gray-500 mb-8">Choose your log-in method below</p>
      <button
        onClick={() => navigate('/signup')}
        className="w-full max-w-xs mb-4 bg-black text-white py-3 rounded-md text-center"
      >
        Continue with Phone or Email
      </button>
      <button
        onClick={handleGoogleSignIn}
        className="w-full max-w-xs mb-4 bg-white text-gray-700 py-3 border border-gray-300 rounded-md"
      >
        <img src="/google-logo.svg" alt="Google" className="inline mr-2" />
        Continue with Google
      </button>
    </div>
  );
}

export default LoginSelection;
