// src/components/CancelSubscription.js

import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path based on your project structure
import { getFunctions, httpsCallable } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { toast } from 'react-toastify'; // Optional: For notifications
import {functions} from '../firebase'; // Adjust the path based on your project structure




const CancelSubscriptionScreen = () => {
  const { currentUser } = useAuth(); // Assuming you have an Auth context
  const [loading, setLoading] = useState(false);

  const handleCancel = async () => {
    const confirm = window.confirm('Are you sure you want to cancel your subscription?');
    if (!confirm) return;

    setLoading(true);
    try {
      const cancelSubscription = httpsCallable(functions, 'cancelSubscription');
      const result = await cancelSubscription({ uid: currentUser.uid });


      if (result.data.success) {
        toast.success(result.data.message); // Using react-toastify for notifications
        // Optionally, update UI or redirect the user
      } else {
        toast.error('Failed to cancel subscription.');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      toast.error(error.message || 'An error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-semibold mb-6 text-center">Cancel Subscription</h2>
        <p className="text-gray-700 mb-6">
          We're sorry to see you go. 
        </p>
        <button
          onClick={handleCancel}
          disabled={loading}
          className={`w-full py-2 px-4 rounded-md text-white ${
            loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
          } transition duration-200`}
        >
          {loading ? 'Cancelling...' : 'Cancel Subscription'}
        </button>
      </div>
    </div>
  );
};

export default CancelSubscriptionScreen;
