import React from 'react';

const AboutScreen = () => {
  return (
    <div className="overflow-x-hidden bg-gradient-to-br from-purple-100 via-pink-100 to-orange-100 min-h-screen">
     <header className="relative py-2 md:py-3 bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between">
            {/* Logo */}
            <div className="flex-shrink-0">
              <a
                href="/"
                className="text-2xl text-black-800 font-inter"
              >
                Avantdemy
              </a>
            </div>

            {/* Mobile Menu Button */}
            <div className="flex lg:hidden">
              <button type="button" className="text-gray-900">
                <svg
                  className="w-7 h-7"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>

            {/* Centered Navigation Links */}
            <div className="hidden lg:absolute lg:inset-y-0 lg:flex lg:items-center lg:justify-center lg:space-x-12 lg:-translate-x-1/2 lg:left-1/2">
              <a
                href="/platform"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Platform
              </a>
              <a
                href="/solutions"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Solutions
              </a>
              <a
                href="/resources"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Resources
              </a>
              <a
                href="/company"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Company
              </a>
              <a
                href="/pricing"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Pricing
              </a>
            </div>

            {/* Right-Aligned Links and Button */}
            <div className="hidden lg:flex lg:items-center lg:space-x-6">
              <a
                href="/login-selection"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Log In
              </a>
              <a
                href="/signup"
                className="text-base font-medium text-gray-900 transition-all duration-200 rounded hover:text-gray-600"
              >
                Free Trial
              </a>
              <a
                href="/demo"
                className="px-5 py-2 text-base font-semibold text-white bg-purple-600 rounded-lg shadow-lg hover:bg-purple-700"
                role="button"
              >
                Get A Demo
              </a>
            </div>
          </div>
        </div>
      </header>

      <section className="relative flex items-center justify-center min-h-[calc(100vh-80px)] sm:py-16 lg:pt-20 xl:pb-0">
  <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl transform -translate-y-20 pb-32">
    <div className="max-w-3xl mx-auto text-center">
      {/* <h1 className="mt-5 text-5xl font-medium tracking-tight text-gray-900 sm:text-6xl">
        The AI Platform That Guarantees Your
        <span className="block whitespace-nowrap">College Success</span>
      </h1> */}
      <h1 className="mt-5 text-5xl font-medium tracking-tight text-gray-900 sm:text-6xl">
        The AI Platform for your
        <span className="block whitespace-nowrap">College Success</span> 
        {/* journey */}
      </h1>

      <p className="max-w-md mx-auto mt-6 text-base leading-7 text-gray-600 font-inter">
      Elevate your college apps with AI-powered tools and learning designed for SAT prep, essay writing, and college research.
      </p>

      <div className="flex items-center justify-center gap-4 mt-10">
        <a
          href="/signup"
          className="px-6 py-2.5 text-base font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
          role="button"
        >
          Start Free Trial
        </a>
        <a
          href="/demo"
          className="px-6 py-2.5 text-base font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700"
          role="button"
        >
          Get A Demo
        </a>
      </div>
    </div>
  </div>
</section>

    </div>
  );
};

export default AboutScreen;
