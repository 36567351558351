//Linear functions

export const systemsLinearEqTwoVars
= {
    title: "Systems of two linear equations in two variables",
    order: 4,
    locked: false, 
    unlockDate: null,
    lessons: [],
       

}